/*
  Statistics Counter
*/

export function statisticsCounter() {
  var counter = 0;
    const numberOfStats = $('.company-stats-section .stat-item-wrp').length+1;

    var observer = new IntersectionObserver(function(entries) {
      if(entries[0].isIntersecting === true)
        if (counter == 0) {
          setTimeout(function(){
            counter++;
            for (let i = 1; i < numberOfStats; i++) {
              document.querySelector('#final-'+ i).classList.remove('d-none');
              document.querySelector('#initial-'+ i).classList.add('d-none');
            }
            countUp();
          }, 200);
        }
    }, { threshold: [0.5] });

    observer.observe(document.querySelector('.company-stats-section'));
}

function countUp() {
  $('.count').each(function () {
    const finalValue = parseFloat($(this).text());
    const isInteger = finalValue % 1 === 0;

    $(this)
      .prop('Counter', 0)
      .animate(
        {
          Counter: finalValue,
        },
        {
          duration: 2000,
          easing: 'swing',
          step: function (now) {
            const formattedValue = now.toLocaleString('en', {
              minimumFractionDigits: isInteger ? 0 : 1,
              maximumFractionDigits: isInteger ? 0 : 1,
            });
            $(this).text(formattedValue);
          },
          complete: function () {
            $(this).text(
              finalValue.toLocaleString('en', {
                minimumFractionDigits: isInteger ? 0 : 1,
                maximumFractionDigits: isInteger ? 0 : 1,
              })
            );
          },
        }
      );
  });
}
