/*
  Creates the functionality of of news items
*/

export function loadMore() {
  $('.left-items .news-item-wrp').slice(0, 4).show();
  $('.right-items .news-item-wrp').slice(0, 4).show();
  if ($('.left-items .news-item-wrp:hidden').length != 0 || $('.right-items .news-item-wrp:hidden').length != 0) {
    $('#loadMoreDesktop').show();
  } else {
    $('#loadMoreDesktop').hide();
  }
  $('#loadMoreDesktop').on('click', function (e) {
    e.preventDefault();
    $('.left-items .news-item-wrp:hidden').slice(0, 1).slideDown();
    $('.right-items .news-item-wrp:hidden').slice(0, 1).slideDown();
    if ($('.left-items .news-item-wrp:hidden').length == 0) {
      $('#loadMoreDesktop').text('No More to view').fadeOut('slow');
      $('#loadMoreDesktop').addClass('load-more-end');
    }
  });

  $('.mobile-items .news-item-wrp').slice(0, 8).show();
  if ($('.mobile-items .news-item-wrp:hidden').length != 0) {
    $('#loadMoreMobile').show();
  } else {
    $('#loadMoreMobile').hide();
  }
  $('#loadMoreMobile').on('click', function (e) {
    e.preventDefault();
    $('.mobile-items .news-item-wrp:hidden').slice(0, 2).slideDown();
    if ($('.mobile-items .news-item-wrp:hidden').length == 0) {
      $('#loadMoreMobile').text('No More to view').fadeOut('slow');
      $('#loadMoreMobile').addClass('load-more-end');
    }
  });
}