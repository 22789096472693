/*
  Testimonial slider
*/

export function testimonialSlider() {

  $('.testimonials-wrp .testimonials-row.slider').flickity({
    pageDots: false,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: 'left',
    adaptiveHeight: true,
  });

  $('.testimonials-wrp').each(function () {
    $(this).find('.flickity-button').appendTo($(this).find('.flickity-button-wrp'));
  });
}