/*
  Makes the header nagivation sticky
*/

import { getCurrentScroll } from './getCurrentScroll';

export function stickyMenu() {
  var shrinkHeader = 5;
  var lastScrollTop = 0;

  $(window).scroll(function () {
    var scroll = getCurrentScroll();
    if (scroll >= shrinkHeader) {
      $('body').addClass('sticky-header');

      // Detect scroll up/down events
      var st = $(this).scrollTop();
      if (st > lastScrollTop){
        $('body').addClass('sticky-hide');
      } else {
        $('body').removeClass('sticky-hide');
      }
      lastScrollTop = st;

    } else {
      $('body').removeClass('sticky-header');
    }
  });

  // Check if on window load, currentScroll is pass shrinkHeader
  var scrollLoad = getCurrentScroll();
  if (scrollLoad >= shrinkHeader) {
    $('body').addClass('sticky-hide');
  } else {
    $('body').removeClass('sticky-hide');
  }
}