import Flickity from 'flickity';
import 'flickity/dist/flickity.pkgd.js';
import 'viewerjs/dist/viewer.min.js';
import 'jquery-viewer/dist/jquery-viewer.min.js';

import { cookieConsent } from './scripts/cookie/cookieConsent';
import { deleteCookie } from './scripts/cookie/deleteCookie';
import { fullWidthVideoPlayerFile } from './scripts/video/fullWidthVideoPlayerFile';
import { videoPopupPlayerFile } from './scripts/video/videoPopupPlayerFile';
import { fullWidthVideoPlayerLink } from './scripts/video/fullWidthVideoPlayerLink';
import { videoPopupPlayerLink } from './scripts/video/videoPopupPlayerLink';
import { delayHomeBannerVideoFile } from './scripts/video/delayHomeBannerVideoFile';
import { headerScripts } from './scripts/header/headerScripts';
import { stickyMenu } from './scripts/header/stickyMenu';
import { loadMore } from './scripts/load-more/loadMore';
import { formScripts } from './scripts/form/formScripts';
import { cardSectionGridSlider } from './scripts/card/cardSectionGridSlider';
import { cardSectionSlider } from './scripts/card/cardSectionSlider';
import { logoSlider } from './scripts/logo/logoSlider';
import { logoSectionGridSlider } from './scripts/logo/logoSectionGridSlider';
import { gallerySectionSlider } from './scripts/gallery/gallerySectionSlider';
import { jqueryViewer } from './scripts/gallery/jqueryViewer';
import { editorContentAdditions } from './scripts/misc/editorContentAdditions';
import { testimonialSlider } from './scripts/testimonial/testimonialSlider';
import { testimonialGridSlider } from './scripts/testimonial/testimonialGridSlider';
import { statisticsCounter } from './scripts/counter/statistcsCounter.js';

export default {
  init() {
    // JavaScript to be fired on all pages
    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    logoSlider();
    cookieConsent();
    deleteCookie('PREF');
    headerScripts();
    stickyMenu();
    logoSectionGridSlider();
    formScripts();
    cardSectionGridSlider();
    cardSectionSlider();
    gallerySectionSlider();
    jqueryViewer('.gallery-images-viewer-v2');
    jqueryViewer('.gallery-images-viewer-v1');
    fullWidthVideoPlayerFile();
    videoPopupPlayerFile();
    fullWidthVideoPlayerLink();
    videoPopupPlayerLink();
    delayHomeBannerVideoFile();
    editorContentAdditions();
    loadMore();
    testimonialGridSlider();
    testimonialSlider();
    buttonArrow();
    statisticsCounter();
  },
};

 // flickity resize to work when specific amount of pixels are resized eg: now at 10px
 const resizeDelta = Math.abs($(window).width() - $(window).data('previousWidth'));

 if (resizeDelta > 10) {
   $('.flickity-viewport').css('height', '200');
 }

 $(window).data('previousWidth', $(window).width());
 // flickity resize to work when specific amount of pixels are resized eg: now at 10px

$(window).on('resize', function () {
  // set header height to sticky overlay
  $('.sticky-overlay').outerHeight($('.header').outerHeight());
});

$(window).on('load', function () {
  $('.cards-section.slider .cards-wrp, .cards-section.grid .cards-wrp, .gallery-section.v1 .gallery-wrapper, .testimonials-wrp .testimonials-row'
  ).flickity('resize');
});

function buttonArrow() {
  $('.btn-read-more').each(function() {
    $(this).html('<span>' + $(this).html() + '</span>');
    $(this).append('<span class="arrow-icon"><span></span></span>');
  });
}
