import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';


export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    // Check if a specific id is in view and fire the CountUp Function
    var counter = 0;
    const numberOfStats = $('#home-company-statistics .stat-item-wrp').length+1;

    var observer = new IntersectionObserver(function(entries) {
      if(entries[0].isIntersecting === true)
        if (counter == 0) {
          setTimeout(function(){
            counter++;
            for (let i = 1; i < numberOfStats; i++) {
              document.querySelector('#final-'+ i).classList.remove('d-none');
              document.querySelector('#initial-'+ i).classList.add('d-none');
            }
            // countUp();
          }, 200);
        }
    }, { threshold: [0.5] });

    observer.observe(document.querySelector('#home-company-statistics'));

    businessGoalsCardSectionMobileSlider();
    logoSectionSlider();
    caseStudiesSlider();
    industriesSlider();
    appendIndustriesSliderControls();
    industriesFormSubmit();

    $('.home-logo-section .section-inner-wrp .logo-wrp, .industries-section .industries-wrp, .industries-section .tags-wrp').flickity('resize');
  },
};

$(window).on('resize', function () {
  industriesSlider();
  appendIndustriesSliderControls();
  $('.home-logo-section .section-inner-wrp .logo-wrp, .industries-section .industries-wrp, .industries-section .tags-wrp').flickity('resize');
});

function logoSectionSlider() {
  if ($('.home-logo-section .section-inner-wrp .logo-wrp')) {
    // Play with this value to change the speed
    let tickerSpeed = 1;

    let flickity = null;
    let isPaused = false;
    const slideshowEl = document.querySelector('.home-logo-section .section-inner-wrp .logo-wrp');

    const update = () => {
      if (isPaused) return;
      if (flickity.slides) {
        flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
        flickity.selectedIndex = flickity.dragEndRestingSelect();
        flickity.updateSelectedSlide();
        flickity.settle(flickity.x);
      }
      window.requestAnimationFrame(update);
    };

    const pause = () => {
      isPaused = true;
    };

    const play = () => {
      if (isPaused) {
        isPaused = false;
        window.requestAnimationFrame(update);
      }
    };

    flickity = new Flickity(slideshowEl, {
      autoPlay: false,
      prevNextButtons: false,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      selectedAttraction: 0.015,
      friction: 0.25,
      watchCSS: true,
    });

    flickity.x = 0;

    slideshowEl.addEventListener('mouseenter', pause, false);
    slideshowEl.addEventListener('focusin', pause, false);
    slideshowEl.addEventListener('mouseleave', play, false);
    slideshowEl.addEventListener('focusout', play, false);

    flickity.on('dragStart', () => {
      isPaused = true;
    });

    update();
  }
}

function caseStudiesSlider() {
  $('.home-case-studies-section .section-inner-wrp .case-studies-wrp .mobile-content').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    adaptiveHeight: true,
    watchCSS: true,
  });
}

function businessGoalsCardSectionMobileSlider() {
  $('.business-goal-section .goal-cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    cellAlign: 'center',
    watchCSS: true,
  });
}

function industriesSlider() {
  var $cellAlign;
  if (window.matchMedia('(min-width: 1200px)').matches) {
    $cellAlign = 'left';
  } else {
    $cellAlign = 'right';
  }

  $('.industries-section .industries-wrp').flickity({
    pageDots: true,
    wrapAround: true,
    adaptiveHeight: true,
  });

  $('.tags-wrp').flickity({
    asNavFor: '.industries-section .industries-wrp',
    contain: true,
    pageDots: false,
    prevNextButtons: false,
    cellAlign: $cellAlign,
    draggable: false,
  });
}

function appendIndustriesSliderControls() {
  if (window.matchMedia('(min-width: 1200px)').matches) {
    $('.industries-wrp').find('.flickity-button').appendTo('.tags-wrp');
  } else {
    $('.tags-wrp').find('.flickity-button').appendTo('.industries-wrp');
  }

  $('.industries-wrp').on( 'dragEnd.flickity', function() {
    $('.industries-section .tags-wrp').flickity('resize');
  });

  $('.industries-wrp .flickity-button').on('click', function(){
    $('.industries-section .tags-wrp').flickity('resize');
  });
}

function industriesFormSubmit() {
  $('.industries-item .wpcf7 form').on('submit', function(){
    setTimeout(() => {
      $('.industries-wrp').flickity('resize');
    }, 1500);
  })
}
